import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import Axios from 'axios'
import Vuelidate from 'vuelidate'
import VueSession from 'vue-session'
import VueToastr from "vue-toastr";
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css/animate.min.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { Chrome } from 'vue-color'

import './modules/general'
import './modules/campaigns'
import './modules/cart'
import './modules/usersettings'
import './modules/storemanagement'

// import this last to catch routes
import './modules/catalog'
import './modules/product'

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueSession)

Vue.use(VueToastr, {
  defaultPosition: "toast-top-center",
  defaultTimeout: 3500,
  defaultClassNames: ["animated", "fadeInDown", "toast-custom"]
});

Vue.component('v-select', vSelect);
Vue.component('c-picker', Chrome);

Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/css/framme.css';
import i18n from './i18n'

Vue.prototype.$http = Axios;
const apiToken = localStorage.getItem('apiToken')
if (apiToken) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = "Token " + apiToken
}

//create v-can directive
Vue.directive('can', {
  bind: function (el, binding){
    if(!store.state.membership.group_data || !store.state.membership.group_data[binding.value]) {
      el.style.display = 'none';
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
